<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2" v-loading="loaders.data">
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Card & Trophies</h1>
      <router-link class="ml-2" :to="{ name: 'AddTrophyCard'}">
        <base-button class="btn-secondary-action title-btn-padding button-font">
          Add New
        </base-button>
      </router-link>
    </div>
    <div class="mt-4 cardStyle">
      <el-table
        :data="response.cardsAndTrophies"
        row-key="id"
        class="table-responsive table-flush pt-4"
        header-row-class-name="thead-light"
      >
        <el-table-column label="TITLE" prop="title">
          <template slot-scope="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="USER TYPE">
          <template slot-scope="scope">
            <span>{{ scope.row.user_tier | userTier }}</span>
          </template>
        </el-table-column>
        <el-table-column label="BASED ON">
          <template slot-scope="scope">
            <span>{{ scope.row.rule_type | ruleType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="ENABLE/DISABLE">
          <template slot-scope="scope">
            <base-switch
              class="status-toggle"
              v-model="scope.row.status"
              onText=""
              offText=""
              @input="updateStatus(scope.row.status, scope.row.id)"></base-switch>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip
              class="item" effect="dark"
              content="View"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <router-link :to="{name: 'ShowTrophyCard', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Edit"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <router-link :to="{name: 'EditTrophyCard', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Delete"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img
                class="pointer"
                @click="promptRemove(row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="'You want to remove this card.'"
        :removeId="request.removeId"
        @onRemove="removeTrophy"
      />
    </div>
  </div>
</template>

<script>
const RemoveModal = () => import("@/components/Modals/RemoveModal.vue");
import {constants} from "@/util/constants";
import {Table, TableColumn} from "element-ui";

export default {
  name: "TrophyCards",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
  },
  data() {
    return {
      loaders: {
        removeModal: false,
        data: false,
      },
      response: {
        cardsAndTrophies: []
      },
      request: {
        removeId: 0,
      },
    }
  },
  filters: {
    userTier(user_tier) {
      let userTiers = ''
      if (user_tier) {
        user_tier.split(',').forEach(function (tier, index, array) {
          userTiers += constants.cardsTrophies.userTierTypes[tier]
          if (index != array.length - 1) userTiers += ', '
        })
      }
      return userTiers
    },

    ruleType(rule_type) {
      return constants.cardsTrophies.ruleTypes[rule_type] ?? ''
    }
  },
  mounted() {
    this.getCardTrophies()
  },
  methods: {
    /**
     * Get all Card Trophies
     */
    getCardTrophies() {
      let vm = this
      vm.loaders.data = true
      vm.$store.dispatch('CardsAndTrophiesModule/_getAllTrophyCards')
        .then(response => {
          vm.request.removeId = 0
          vm.response.cardsAndTrophies = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Trophy Card',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.data = false
        })
    },

    /**
     * Update card status
     * @param status
     * @param id
     */
    updateStatus(status, id) {
      let vm = this
      vm.loaders.data = true
      const payload = {
        id,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('CardsAndTrophiesModule/_updateStatusTrophyCard', payload)
        .then(response => {
          vm.request.removeId = 0
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updates Successfully!'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Trophy Card',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.data = false
        })
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.request.removeId = row.id;
    },

    /**
     * Remove Trophy Card
     */
    removeTrophy() {
      let vm = this
      vm.loaders.removeModal = true
      const payload = {
        id: vm.request.removeId
      }
      vm.$store.dispatch('CardsAndTrophiesModule/_deleteTrophyCard', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Trophy Card deleted successfully.'
          })
          vm.getCardTrophies()
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Trophy Card',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.removeModal = false
        })
    }
  }
}
</script>

<style scoped>

</style>
